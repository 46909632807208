module.exports = function() {
    var bloc_video = document.querySelector('.bloc-video');

    if(bloc_video) {
        var video = document.querySelector('.bloc-video__video video');
        var preview =  document.querySelector('.bloc-video__video--preview');
        var play = document.querySelector('.bloc-video .play-button');

        play.addEventListener('click', (e) => {
            preview.classList.add('is-hide');
            play.classList.add('is-hide');
            video.play();
        });

    }
}
