var firstPixel  = require('./core/core.js'),
    home        = require('./functions/home.js'),
    contact     = require('./functions/contact.js'),
    diaporama   = require('./functions/diaporama.js'),
    video       = require('./functions/video.js'),
    simple      = require('./functions/simple.js');

jQuery(document).ready(function($){
    firstPixel();
    $(document).foundation();

    home();
    contact();
    diaporama(); 
    video();
    simple();
});
