module.exports = function(){
    if (document.querySelector('.home')) {
        $('.home__slider .slider-wrapper').slick({
            prevArrow: document.querySelector('.slider-arrow.left'),
            nextArrow: document.querySelector('.slider-arrow.right'),
            autoplay: true, /* this is the new line */
            autoplaySpeed: 3000,
        });

        // if (document.querySelector('.video')) {
        //   let video = document.querySelector('.video video');
        //   let playButton = document.querySelector('.video a');
        //
        //   playButton.addEventListener(
        //       'click',
        //       (e) => {
        //           e.preventDefault();
        //           video.play();
        //           video.setAttribute('controls','');
        //           playButton.style.display = 'none';
        //       }
        //   )
        // }

        let dates = document.querySelectorAll('.news ul li span');

        dates.forEach(
            (date) => {
                date.style.fontSize = '20px';
                date.style.paddingTop = '17px';
                date.style.paddingLeft = '8px';
                date.style.paddingRight = '8px';

                if (date.innerHTML.length > 4) {
                    date.innerHTML = date.innerHTML.slice(0,3);
                }
            }
        )

        $('.home__testimonies__slider__container').slick({
            prevArrow: document.querySelector('.home__testimonies__slider .prev'),
            nextArrow: document.querySelector('.home__testimonies__slider .next')
        });
    }
}
