module.exports = function(){

  var single = document.querySelector('.page-template-page-single');
  var sections = document.querySelectorAll('.page-template-page-single section');
  var sidebar = document.querySelector('.sidebar');

  if (single && sections && sidebar) {
    var columns = document.querySelectorAll('.medium-10');
    for (var i = 0; i < columns.length; i++) {
      if (columns[i].classList.contains('medium-centered')) {
        columns[i].classList.remove('medium-centered');
      }
      columns[i].classList.remove('medium-10');
      columns[i].classList.add('medium-11');
    }
  }

}
