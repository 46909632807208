
module.exports = function(){
  var ct = document.querySelector('.page-template-page-contact');
  if (ct) {
    var confirmation = document.querySelector('.contact__content .gform_confirmation_message');

    var newsletter = document.querySelector('.contact__content--newsletter');

    if (confirmation) {
      newsletter.style.display ='none';
    }
  }
}
